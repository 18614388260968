/* this file is transformed by vux-loader */
/* eslint-disable */
import XHeader from "vux/src/components/x-header/index.vue";
import AgendaList from './widget/AgendaList.vue';
import { _httpGet, _urlHost } from './util/restapi';
export default {
  name: 'AgendasView',
  components: {
    XHeader,
    AgendaList
  },
  data: function () {
    return {
      title: '会议列表',
      setting: {
        id: '',
        orgId: '',
        pageConfig: {
          sections: [],
          departments: [],
          slides: []
        }
      }
    };
  },
  methods: {
    onTitleUpdated(title) {
      console.log('title' + title);
      this.title = title;
    },
    getSetting() {
      let that = this;
      _httpGet('/json/yj/mpsetting', {
        id: this.orgId
      }).then(function (data) {
        that.setting = data.data;
        for (let i in that.setting.pageConfig.slides) {
          that.setting.pageConfig.slides[i].img = _urlHost(that.setting.pageConfig.slides[i].url);
          that.setting.pageConfig.slides[i].url = 'javascript:';
        }
        console.log(data);
      }).catch(function (data) {
        console.log(data);
      });
    }
  },
  watch: {},
  created: function () {
    this.store.hideTabBar();
    this.orgId = this.$route.query.orgId;
    this.getSetting();
  }
};