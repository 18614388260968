/* this file is transformed by vux-loader */
/* eslint-disable */
import Tabbar from "vux/src/components/tabbar/tabbar.vue";
import TabbarItem from "vux/src/components/tabbar/tabbar-item.vue";
export default {
  name: 'App',
  components: {
    Tabbar,
    TabbarItem
  },
  data: function () {
    return {
      state: this.store.state
    };
  },
  methods: {},
  computed: {},
  created: function () {
    console.log('App.vue');
    this.$store;
  }
};