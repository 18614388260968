/* this file is transformed by vux-loader */
/* eslint-disable */
import AgendaList from './widget/AgendaList.vue';
import AreaList from './widget/AreaList.vue';
import LoadMore from "vux/src/components/load-more/index.vue";
import { _httpGet, _urlHost } from './util/restapi';
export default {
  name: 'App',
  components: {
    LoadMore,
    AgendaList,
    AreaList
  },
  data: function () {
    return {
      orgId: '',
      param: {
        s: '',
        mode: 1
      },
      searchOption: {
        s: ''
      },
      setting: {
        id: '',
        orgId: '',
        pageConfig: {
          departments: [],
          slides: [],
          footer: {
            power: ''
          }
        }
      }
    };
  },
  methods: {
    getSetting() {
      let that = this;
      _httpGet('/json/yj/mpsetting', {
        id: this.orgId
      }).then(function (data) {
        that.setting = data.data;
        for (let i in that.setting.pageConfig.slides) {
          that.setting.pageConfig.slides[i].img = _urlHost(that.setting.pageConfig.slides[i].url);
          that.setting.pageConfig.slides[i].url = that.setting.pageConfig.slides[i].thumbnailUrl;
        }
        console.log(data);
      }).catch(function (data) {
        console.log(data);
      });
    }
  },
  created: function () {
    this.store.showTabBar();
    this.orgId = this.$route.query.orgId;
    this.getSetting();
  }
};