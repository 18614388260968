/* this file is transformed by vux-loader */
/* eslint-disable */
import XHeader from "vux/src/components/x-header/index.vue";
import Flexbox from "vux/src/components/flexbox/flexbox.vue";
import FlexboxItem from "vux/src/components/flexbox/flexbox-item.vue";
import Group from "vux/src/components/group/index.vue";
import Cell from "vux/src/components/cell/index.vue";
import XImg from "vux/src/components/x-img/index.vue";
import { _httpGet, _signinUrl, _signoutUrl, _profileUrl } from './util/restapi';
export default {
  name: 'AreasView',
  components: {
    XImg,
    Flexbox,
    XHeader,
    FlexboxItem,
    Cell,
    Group
  },
  data: function () {
    return {
      user: {
        userName: '',
        headimgurl: '',
        id: ''
      },
      title: '我的',
      setting: {
        id: '',
        orgId: '',
        pageConfig: {
          slides: []
        }
      }
    };
  },
  methods: {
    authUrl() {
      let ret = _signinUrl(this.setting.orgId, '', _profileUrl());
      console.log(ret);
      return ret;
    },
    signoutUrl() {
      return _signoutUrl(this.setting.orgId, '', _profileUrl());
    },
    onClickTitle() {
      let ret = this.signoutUrl();
      console.log(ret);
      window.location.href = ret;
      return;
    },
    getSetting() {
      let that = this;
      _httpGet('/json/yj/mpsetting', {
        id: this.orgId
      }).then(function (data) {
        that.setting = data.data;

        //        for(let i in that.setting.pageConfig.slides) {
        //          that.setting.pageConfig.slides[i].img = _urlHost(that.setting.pageConfig.slides[i].url)
        //          that.setting.pageConfig.slides[i].url = 'javascript:'
        //        }
        console.log(data);
      }).catch(function (data) {
        console.log(data);
      });
    },
    getUser: function () {
      let that = this;
      that.$vux.loading.show({
        text: 'Loading'
      });
      _httpGet('/json/yj/user', {
        orgId: this.orgId
      }).then(data => {
        console.log(data);
        that.user = data.data;
        that.$vux.loading.hide();
      }).catch(function (data) {
        console.log(data);
        that.$vux.loading.hide();
      });
    }
  },
  watch: {},
  created: function () {
    this.store.showTabBar();
    this.store.setTab(1);

    // We initially sync the internalValue with the value passed in by the parent
    this.orgId = this.$route.query.orgId;
    this.getSetting();
    this.getUser();
  }
};