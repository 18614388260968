/* this file is transformed by vux-loader */
/* eslint-disable */
import HomeView from '@/components/HomeView';
import ProfileView from '@/components/ProfileView';
import FavoriteView from '@/components/FavoriteView';
import AreasView from '@/components/AreasView';
import AgendasView from '@/components/AgendasView';
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
export default new Router({
  routes: [{
    path: '/',
    name: 'HomeView',
    component: HomeView,
    // component: resolve => (require(['@/components/Home'], resolve)),
    meta: {
      title: '术和直播'
    }
  }, {
    path: '/profile',
    name: 'ProfileView',
    component: ProfileView,
    // component: resolve => (require(['@/components/Home'], resolve)),
    meta: {
      title: '个人中心'
    }
  }, {
    path: '/favorite',
    name: 'FavoriteView',
    component: FavoriteView,
    // component: resolve => (require(['@/components/Home'], resolve)),
    meta: {
      title: '收藏和预约'
    }
  }, {
    path: '/areas',
    name: 'AreasView',
    component: AreasView,
    // component: resolve => (require(['@/components/Home'], resolve)),
    meta: {
      title: '专区'
    }
  }, {
    path: '/agendas',
    name: 'AgendasView',
    component: AgendasView,
    // component: resolve => (require(['@/components/Home'], resolve)),
    meta: {
      title: '会议列表'
    }
  }]
});