/* this file is transformed by vux-loader */
/* eslint-disable */
import Msg from "vux/src/components/msg/index.vue";
import XHeader from "vux/src/components/x-header/index.vue";
import LoadMore from "vux/src/components/load-more/index.vue";
import Flexbox from "vux/src/components/flexbox/flexbox.vue";
import FlexboxItem from "vux/src/components/flexbox/flexbox-item.vue";
import XImg from "vux/src/components/x-img/index.vue";
import { _httpGet, _agendaUrl, _filtersToParams } from './util/restapi';
export default {
  name: 'FavoriteView',
  components: {
    Flexbox,
    Msg,
    FlexboxItem,
    XImg,
    LoadMore,
    XHeader
  },
  data: function () {
    return {
      title: '',
      searchOption: {
        pn: 1,
        ps: 20,
        s: '',
        kvs: {
          ftype: {
            k: 'ftype',
            v: this.$route.query.ftype,
            wild: false
          }
        }
      },
      data: {
        list: {
          objs: [],
          count: 0
        }
      }
    };
  },
  methods: {
    agendaUrl(agenda) {
      return _agendaUrl(agenda.orgId, agenda.id, agenda.liveStatus, agenda.reserved);
    },
    hasMore(count, pn, ps) {
      return count > pn * ps;
    },
    getMore() {
      this.searchOption.pn += 1;
      this.getObjs();
    },
    getObjs() {
      let that = this;
      this.$vux.loading.show({
        text: 'Loading'
      });
      _httpGet('/json/yj/favorites', {
        s: that.searchOption.s,
        pn: that.searchOption.pn,
        ps: that.searchOption.ps,
        kvs: _filtersToParams(that.searchOption.kvs)
      }).then(function (data) {
        that.data.list.count = data.data.count;
        that.data.list.objs = that.data.list.objs.concat(data.data.objs);
        console.log(data);
        that.$vux.loading.hide();
      }).catch(function (data) {
        that.$vux.loading.hide();
        console.log(data);
      });
    },
    getTitle() {
      let ret = '我的';
      if (this.searchOption.kvs.ftype.v === 'SUBSCRIBE') {
        ret += '预约';
      } else if (this.searchOption.kvs.ftype.v === 'FAVORITE') {
        ret += '收藏';
      }
      return ret;
    }
  },
  watch: {},
  created: function () {
    this.store.hideTabBar();
    this.orgId = this.$route.query.orgId;
    this.getObjs();
  }
};