/* this file is transformed by vux-loader */
/* eslint-disable */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import VueResource from 'vue-resource';
import VueAnimateNumber from 'vue-animate-number';

//import VueI18n from 'vue-i18n';

import '/node_modules/element-ui/lib/theme-chalk/index.css';
import '/public/stylesheets/common/variable.less';
import '/public/stylesheets/common/icon.less';
import '/public/stylesheets/common/vux.less';
import '/public/stylesheets/common/widgets.less';
import '/public/stylesheets/common/main.less';
Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(VueAnimateNumber);
Vue.use(LoadingPlugin);
//Vue.use(VueI18n)

//const i18n = new VueI18n({
//  locale: 'cn',
//  messages: {
//    cn: {
//      Total:'合计'
//    },
//    en: {
//
//    }
//  }
//})
import LoadingPlugin from "vux/src/plugins/loading/index.js";
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
var store = {
  debug: true,
  state: {
    showTabBar: true,
    tab: 0
  },
  setTab(tab) {
    if (this.debug) console.log('setTab ' + tab);
    this.state.tab = tab;
  },
  showTabBar() {
    if (this.debug) console.log('showTabBar triggered');
    this.state.showTabBar = true;
  },
  hideTabBar() {
    if (this.debug) console.log('hideTabBar triggered');
    this.state.showTabBar = false;
  }
};
Vue.prototype.store = store;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  //i18n,
  components: {
    App
  },
  template: '<App/>'
});