/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from 'axios';
const _host = 'http://sureheal.methealth.cn';
//const _host = ''

export function isSuccess(data) {
  return data.status == 'SUCCESS';
}
export function _filtersToParams(filters) {
  var kvs = _filtersToArray(filters);
  if (kvs.length > 0) return JSON.stringify(kvs);else return null;
}
export function isString(str) {
  if (Object.prototype.toString.call(str) === "[object String]") {
    return true;
  } else {
    return false;
  }
}
export function _filtersToArray(filters) {
  var kvs = [];
  console.log('here');
  if (filters) for (var i in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, i)) {
      var filter = filters[i];
      if (Object.prototype.hasOwnProperty.call(filter, 'k') && Object.prototype.hasOwnProperty.call(filter, 'v') && filter.v !== null && filter.v !== undefined && (isString(filter.v) && filter.v.trim().length > 0 || !isString(filter.v)))
        // filter.v = filter.v.trim();
        kvs.push(filter);
    }
  }
  return kvs;
}

/***
 *  Http get and Http post with Promise
 */
export function _httpGet(url, params) {
  url = _host + url;
  return new Promise(function (resolve, reject) {
    axios.get(url, {
      params: params
    }).then(function (response) {
      if (isSuccess(response.data)) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    }).catch(function (response) {
      console.log(response);
      reject(response.data);
    });
  });
}
export function _httpPost(url, data) {
  return new Promise(function (resolve, reject) {
    axios.post(url, data).then(function (response) {
      if (isSuccess(response.data)) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    }).catch(function (response) {
      console.log(response);
      reject(response.data);
    });
  });
}
export function _httpPostToast(url, data, that) {
  return new Promise(function (resolve, reject) {
    _httpPost(url, data).then(function (data) {
      if (that && that.$message) {
        //Element-UI
        that.$message({
          message: data.description,
          type: 'success'
        });
      } else if (that && that.$vux) {
        //Vux
        that.$vux.toast.show({
          type: 'success',
          text: data.description
        });
      }
      resolve(data);
    }).catch(function (data) {
      if (that && that.$message) {
        //Element-UI
        that.$message.error(data.description);
      } else if (that && that.$vux) {
        //Vux
        that.$vux.toast.show({
          type: 'warn',
          text: data.description
        });
      }
      reject(data);
    });
  });
}
export function formatDate(time, fmt) {
  let o = {
    "M+": time.getMonth() + 1,
    //月份
    "d+": time.getDate(),
    //日
    "h+": time.getHours(),
    //小时
    "m+": time.getMinutes(),
    //分
    "s+": time.getSeconds(),
    //秒
    "q+": Math.floor((time.getMonth() + 3) / 3),
    //季度
    "S": time.getMilliseconds() //毫秒
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (time.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    }
  }
  return fmt;
}
export function _utilDateFormat(date, fmt) {
  if (!date || date == 0) return '';
  return formatDate(new Date(date), fmt);
}
export function _urlHost(url) {
  return _host + url;
}
export function _agendaUrl(orgId, site, liveStatus, summit) {
  if (summit != 'TRUE') {
    return _liveUrl(orgId, site);
  } else {
    return _homeUrl(orgId, site);
  }
}
export function _homeUrl(orgId, site) {
  return _urlHost('/mp/home/' + orgId + '?site=' + site);
}
export function _liveUrl(orgId, site) {
  return _urlHost('/mp/yj/live?orgId=' + orgId + '&site=' + site);
}
export function _signinUrl(orgId, site, back) {
  return _urlHost('/mp/yj/signin?orgId=' + orgId + '&site=' + site + '&back=' + encodeURIComponent(back));
}
export function _signoutUrl(orgId, site, back) {
  return _urlHost('/mp/yj/signout?orgId=' + orgId + '&site=' + site + '&back=' + encodeURIComponent(back));
}
export function _profileUrl() {
  return _urlHost('/yj#/profile');
}