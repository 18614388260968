/* this file is transformed by vux-loader */
/* eslint-disable */
import Search from "vux/src/components/search/index.vue";
import LoadMore from "vux/src/components/load-more/index.vue";
import XImg from "vux/src/components/x-img/index.vue";
import { _httpGet, _filtersToParams } from '../util/restapi';
export default {
  name: 'AreaList',
  components: {
    XImg,
    LoadMore,
    Search
  },
  props: {
    setting: {
      id: '',
      orgId: '',
      pageConfig: {
        slides: []
      }
    },
    param: {
      s: '',
      mode: 0
    }
  },
  data: function () {
    return {
      data: {
        list: {
          objs: [],
          count: 0
        }
      },
      searchOption: {
        pn: 1,
        ps: 20,
        s: '',
        kvs: {
          section: {
            k: 'agendaConfig.category.section.title',
            v: '',
            wild: false
          },
          department: {
            k: 'agendaConfig.category.department.title',
            v: '',
            wild: false
          }
        }
      }
    };
  },
  methods: {
    hasMore(count, pn, ps) {
      return count > pn * ps;
    },
    getMore() {
      this.searchOption.pn += 1;
      this.getObjs();
    },
    getObjs() {
      let that = this;
      this.$vux.loading.show({
        text: 'Loading'
      });
      _httpGet('/json/yj/areas', {
        orgId: this.setting.orgId,
        s: that.searchOption.s,
        pn: that.searchOption.pn,
        ps: that.searchOption.ps,
        kvs: _filtersToParams(that.searchOption.kvs)
      }).then(function (data) {
        console.log(data);
        that.data.list.count = data.data.count;
        that.data.list.objs = that.data.list.objs.concat(data.data.objs);
        that.$vux.loading.hide();
      }).catch(function (data) {
        that.$vux.loading.hide();
        console.log(data);
      });
    },
    refreshObjs() {
      this.searchOption.pn = 1;
      this.data.list = {
        objs: [],
        count: 0
      };
      this.getObjs();
    },
    resultClick(item) {
      console.log(item);
      // window.alert('you click the result item: ' + JSON.stringify(item))
    },

    getResult(val) {
      console.log('on-change', val);
    },
    onSubmit() {
      this.$refs.search.setBlur();
      this.refreshObjs();
    },
    onFocus() {
      console.log('on focus');
    },
    onCancel() {
      console.log('on cancel');
      this.searchOption.s = '';
      this.refreshObjs();
    }
  },
  watch: {
    'setting.orgId': function (val) {
      if (val) {
        this.getObjs();
      }
    },
    'param.s': function (val) {
      if (this.searchOption.s != val) {
        this.searchOption.s = val;
        this.refreshObjs();
      }
    }
  },
  created: function () {}
};