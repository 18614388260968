/* this file is transformed by vux-loader */
/* eslint-disable */
import Msg from "vux/src/components/msg/index.vue";
import Flexbox from "vux/src/components/flexbox/flexbox.vue";
import FlexboxItem from "vux/src/components/flexbox/flexbox-item.vue";
import LoadMore from "vux/src/components/load-more/index.vue";
import Search from "vux/src/components/search/index.vue";
import XImg from "vux/src/components/x-img/index.vue";
import Tab from "vux/src/components/tab/tab.vue";
import TabItem from "vux/src/components/tab/tab-item.vue";
import Swiper from "vux/src/components/swiper/swiper.vue";
import { _httpGet, _filtersToParams, _utilDateFormat, _agendaUrl } from '../util/restapi';
export default {
  name: 'AgendaList',
  components: {
    Flexbox,
    LoadMore,
    Msg,
    Search,
    FlexboxItem,
    Tab,
    XImg,
    TabItem,
    Swiper
  },
  props: {
    setting: {
      id: '',
      orgId: '',
      pageConfig: {
        sections: [],
        departments: [],
        slides: []
      }
    },
    param: {
      more: true,
      area: '',
      s: '',
      summit: 'FALSE'
    }
  },
  data: function () {
    return {
      data: {
        area: {
          id: '',
          title: '',
          files: []
        },
        list: {
          objs: [],
          count: 0
        }
      },
      page: {
        section: 0,
        department: -1
      },
      searchOption: {
        pn: 1,
        ps: 20,
        s: '',
        kvs: {
          summit: {
            k: 'agendaConfig.category.summit.ok',
            v: '',
            wild: false
          },
          area: {
            k: 'agendaConfig.category.area.id',
            v: '',
            wild: false
          },
          section: {
            k: 'agendaConfig.category.section.title',
            v: '',
            wild: false
          },
          department: {
            k: 'agendaConfig.category.department.title',
            v: '',
            wild: false
          }
        }
      }
    };
  },
  methods: {
    getPeriod(agenda) {
      let ret = agenda.period;
      if (!ret) {
        if (agenda.agendaConfig.liveTime > 0) {
          ret = _utilDateFormat(agenda.agendaConfig.liveTime, 'yyyy年MM月dd日 hh:mm');
        } else {
          ret = this.getDayDate(agenda.regPeriod.openTime, true);
          if (agenda.regPeriod.closeTime > 0) {
            if (ret != this.getDayDate(agenda.regPeriod.closeTime, true)) {
              ret = ret + ' - ' + this.getDayDate(agenda.regPeriod.closeTime);
            }
          }
        }
      }
      return ret;
    },
    getDayDate(date, withYear) {
      if (withYear) {
        return _utilDateFormat(date, 'yyyy年MM月dd日');
      }
      {
        return _utilDateFormat(date, 'MM月dd日');
      }
    },
    agendaUrl(agenda) {
      return _agendaUrl(agenda.orgId, agenda.id, agenda.agendaConfig.liveStatus, agenda.agendaConfig.category.summit.ok);
    },
    refreshObjs() {
      this.searchOption.pn = 1;
      this.data.list = {
        objs: [],
        count: 0
      };
      this.getObjs();
    },
    hasMore(count, pn, ps) {
      return count > pn * ps;
    },
    getMore() {
      this.searchOption.pn += 1;
      this.getObjs();
    },
    onSectionClick(index) {
      console.log('on section click:', index);
      this.refreshObjs();
    },
    onDepartmentClick(index) {
      console.log('on department click:', index);
      this.refreshObjs();
    },
    isSummit() {
      return this.param && this.param.summit == 'TRUE';
    },
    isArea() {
      return this.searchOption.kvs.area.v;
    },
    getArea() {
      if (this.isArea()) {
        if (this.data.list.objs.length > 0) {
          this.data.area = this.data.list.objs[0].agendaConfig.category.area;
          this.$emit('on-title-updated', this.data.area.title);
        }
      }
    },
    refresh() {
      this.param.s = this.searchOption.s;
    },
    resultClick(item) {
      console.log(item);
      // window.alert('you click the result item: ' + JSON.stringify(item))
    },

    getResult(val) {
      console.log('on-change', val);
    },
    onSubmit() {
      this.$refs.searchHome.setBlur();
      this.refresh();
    },
    onFocus() {
      console.log('on focus');
    },
    onCancel() {
      console.log('on cancel');
      this.searchOption.s = '';
      this.refresh();
    },
    getObjs() {
      console.log("getObjs");
      let that = this;
      if (!(that.isArea() || that.isSummit()) && that.setting.pageConfig.sections.length > 0) {
        that.searchOption.kvs.section.v = that.setting.pageConfig.sections[that.page.section].title;
      }
      if (that.isSummit()) {
        that.searchOption.kvs.summit.v = that.param.summit;
      }
      if (that.page.department > 0) {
        if (that.setting.pageConfig.departments.length > 0) {
          that.searchOption.kvs.department.v = that.setting.pageConfig.departments[that.page.department - 1].title;
        }
      } else {
        that.searchOption.kvs.department.v = '';
      }
      this.$vux.loading.show({
        text: 'Loading'
      });
      _httpGet('/json/yj/agendas', {
        orgId: this.setting.orgId,
        s: that.searchOption.s,
        pn: that.searchOption.pn,
        ps: that.searchOption.ps,
        kvs: _filtersToParams(that.searchOption.kvs)
      }).then(function (data) {
        console.log(data);
        that.data.list.count = data.data.count;
        that.data.list.objs = that.data.list.objs.concat(data.data.objs);
        that.getArea();
        that.$vux.loading.hide();
      }).catch(function (data) {
        that.$vux.loading.hide();
        console.log(data);
      });
    }
  },
  watch: {
    'setting.orgId': function (val) {
      console.log('setting.orgId: ' + val);
      if (val) {
        this.page.department = 0;
        this.getObjs();
      }
    },
    'param.s': function () {
      //      if(this.searchOption.s != val) {
      //        this.searchOption.s = val
      //        this.refreshObjs()
      //      }
      console.log('refreshObjs on param.s changing');
      this.refreshObjs();
    }
  },
  created: function () {
    // We initially sync the internalValue with the value passed in by the parent
    this.searchOption.kvs.area.v = this.$route.query.area;
    console.log(this.param);
  }
};