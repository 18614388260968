/* this file is transformed by vux-loader */
/* eslint-disable */
import XHeader from "vux/src/components/x-header/index.vue";
import Tab from "vux/src/components/tab/tab.vue";
import TabItem from "vux/src/components/tab/tab-item.vue";
import AreaList from './widget/AreaList.vue';
import AgendaList from './widget/AgendaList.vue';
import { _httpGet, _urlHost } from './util/restapi';
export default {
  name: 'AreasView',
  components: {
    Tab,
    TabItem,
    XHeader,
    AgendaList,
    AreaList
  },
  data: function () {
    return {
      title: '专区',
      setting: {
        id: '',
        orgId: '',
        start: '',
        pageConfig: {
          sections: [],
          departments: [],
          slides: []
        }
      },
      settingAgenda: {
        id: '',
        orgId: '',
        start: '',
        pageConfig: {
          sections: [],
          departments: [],
          slides: []
        }
      },
      param: {
        summit: 'TRUE'
      },
      page: {
        tabSelected: 0
      }
    };
  },
  methods: {
    onItemClick(val) {
      if (val == 1) {
        //this.$refs.agendas.getObjs()
        this.settingAgenda = this.setting;
      }
      console.log('onItemClick: ' + val);
    },
    onTitleUpdated(title) {
      console.log('title' + title);
    },
    getSetting() {
      let that = this;
      _httpGet('/json/yj/mpsetting', {
        id: this.orgId
      }).then(function (data) {
        that.setting = data.data;
        for (let i in that.setting.pageConfig.slides) {
          that.setting.pageConfig.slides[i].img = _urlHost(that.setting.pageConfig.slides[i].url);
          that.setting.pageConfig.slides[i].url = 'javascript:';
        }
        console.log(data);
      }).catch(function (data) {
        console.log(data);
      });
    }
  },
  watch: {},
  created: function () {
    this.store.hideTabBar();
    this.orgId = this.$route.query.orgId;
    this.getSetting();
  }
};